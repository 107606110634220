<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="name"
    width="90%"
    destroy-on-close
    >
  <el-scrollbar :style="{height:contentStyleObj}">
  <div class="big_box4">
    <div class="top_title3">
      <h4>所得减免优惠明细表</h4>
    </div>
    <div class="cent">
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
          <td rowspan="3" class="center" style="width:8%">减免项目</td>
          <td rowspan="2" class="center" >项目名称</td>
          <td rowspan="2" class="center" >优惠事项名称</td>
          <td rowspan="2" class="center" >优惠方式</td>
          <td rowspan="2" class="center" >项目收入</td>
          <td rowspan="2" class="center" >项目成本</td>
          <td rowspan="2" class="center" >相关税费</td>
          <td rowspan="2" class="center" >应分摊期间费用</td>
          <td rowspan="2" class="center" >纳税调整额</td>
          <td colspan="2" class="center" >项目所得额</td>
          <td rowspan="2" class="center" >减免所得额</td>
        </tr>
        <tr class="top_bg">
          <td class="center">免税项目</td>
          <td class="center">减半项目</td>
        </tr>
        <tr class="top_bg">
          <td style="width:10%">1</td>
          <td style="width:11%">2</td>
          <td style="width:9%">3</td>
          <td style="width:8%">4</td>
          <td style="width:8%">5</td>
          <td style="width:8%">6</td>
          <td style="width:8%">7</td>
          <td style="width:6%">8</td>
          <td style="width:8%">9</td>
          <td style="width:8%">10</td>
          <td style="width:8%">11(9+10x50%)</td>
        </tr>
        <!-- 一、农、林、牧、渔业项目 -->
        <tr class="position_tr">
          <td style="width:8%" :rowspan="(((list[0].items).length) + 2)" class="top_bg">一、农、林、牧、渔业项目</td>
          <td style="width:10%">
            <el-input size="small" v-model="list[0].items[0].projectName"></el-input>
          </td>
          <td style="width:11%">
            <el-select @change="changeProduct1(list[0].items[0])" v-model="list[0].items[0].projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td style="width:8%">
            <el-select v-model="list[0].items[0].mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in list[0].items[0].options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td style="width:9%" v-model:amount="list[0].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:8%" v-model:amount="list[0].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:8%" v-model:amount="list[0].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:8%" v-model:amount="list[0].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:6%" v-model:amount="list[0].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td style="width:8%" v-model:amount="list[0].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:8%" v-model:amount="list[0].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:8%" v-model:amount="list[0].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[0].items,list[0],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[0].items,list[0],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[0].items" :key="$index" class="position_tr">
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0">
            <el-select @change="changeProduct1(item)" v-model="item.projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in item.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[0].items,list[0],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td style="width:10%">小计</td>
          <td style="width:11%">*</td>
          <td style="width:9%">*</td>
          <td style="width:8%">*</td>
          <td style="width:8%">*</td>
          <td style="width:8%">*</td>
          <td style="width:8%">*</td>
          <td style="width:6%">*</td>
          <td style="width:8%">*</td>
          <td style="width:8%">*</td>
          <td style="width:8%">{{ amount1 }}</td>
        </tr>
        <!-- 国家重点扶持的公共基础设施项目 -->
        <tr class="position_tr">
          <td :rowspan="(list[1].items.length + 2)" class="top_bg">二、国家重点扶持的公共基础设施项目</td>
          <td>
            <el-input size="small" v-model="list[1].items[0].projectName"></el-input>
          </td>
          <td>
            <el-select v-model="list[1].items[0].projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td>
            <el-select v-model="list[1].items[0].mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="list[1].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[1].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[1].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[1].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[1].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[1].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[1].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[1].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[1].items,list[1],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[1].items,list[1],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[1].items" :key="$index" class="position_tr">
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[1].items,list[1],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount2 }}</td>
        </tr>
        <!-- 符合条件的环境保护、节能节水项目 -->
        <tr class="position_tr">
          <td :rowspan="(list[2].items.length + 2)" class="top_bg">三、符合条件的环境保护、节能节水项目</td>
          <td>
            <el-input size="small" v-model="list[2].items[0].projectName"></el-input>
          </td>
          <td>
            <el-cascader
              v-model="list[2].items[0].projectItem"
              :options="productOption3"
              size="small"
              :show-all-levels="false"
              @change="changeProduct3(list[2].items[0])"
            ></el-cascader>
          </td>
          <td>
            <el-select v-model="list[2].items[0].mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="list[2].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[2].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[2].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[2].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[2].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[2].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[2].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[2].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[2].items,list[2],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[2].items,list[2],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[2].items" :key="$index" class="position_tr">
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0">
            <el-cascader
              v-model="item.projectItem"
              :options="productOption3"
              size="small"
              :show-all-levels="false"
              @change="changeProduct3(item)"
            ></el-cascader>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[2].items,list[2],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount3 }}</td>
        </tr>
        <!-- 符合条件的技术转让项目 -->
        <tr class="position_tr">
          <td :rowspan="(list[3].items.length + 2)" class="top_bg">四、符合条件的技术转让项目</td>
          <td>
            <el-input size="small" v-model="list[3].items[0].projectName"></el-input>
          </td>
          <td>
            <el-select v-model="list[3].items[0].projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption4"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td>
            <el-select v-model="list[3].items[0].mode" placeholder="请选择优惠方式" size="small" filterable clearable disabled>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="list[3].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[3].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[3].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[3].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[3].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[3].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[3].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[3].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[3].items,list[3],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[3].items,list[3],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[3].items" :key="$index" class="position_tr" >
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption4"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.mode" placeholder="请选择优惠方式" size="small" filterable clearable disabled>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[3].items,list[3],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount4 }}</td>
        </tr>
        <!-- 清洁发展机制项目 -->
        <tr class="position_tr">
          <td :rowspan="(list[4].items.length + 2)" class="top_bg">五、清洁发展机制项目</td>
          <td>
            <el-input size="small" v-model="list[4].items[0].projectName"></el-input>
          </td>
          <td>
            <el-select v-model="list[4].items[0].projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption5"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td>
            <el-select v-model="list[4].items[0].mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="list[4].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[4].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[4].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[4].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[4].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[4].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[4].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[4].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem5(list[4].items,list[4],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[4].items,list[4],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[4].items" :key="$index" class="position_tr" >
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption5"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[4].items,list[4],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount5 }}</td>
        </tr>
        <!-- 符合条件的节能服务公司实施的合同能源管理项目 -->
        <tr class="position_tr">
          <td :rowspan="(list[5].items.length + 2)" class="top_bg">六、符合条件的节能服务公司实施的合同能源管理项目</td>
          <td>
            <el-input size="small" v-model="list[5].items[0].projectName"></el-input>
          </td>
          <td>
            <el-select v-model="list[5].items[0].projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption6"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td>
            <el-select v-model="list[5].items[0].mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="list[5].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[5].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[5].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[5].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[5].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[5].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[5].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[5].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem6(list[5].items,list[5],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[5].items,list[5],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[5].items" :key="$index" class="position_tr" >
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.projectItem" placeholder="请选择优惠事项名称" size="small" filterable clearable>
              <el-option
                v-for="item in productOption5"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td v-if="$index != 0">
            <el-select v-model="item.mode" placeholder="请选择优惠方式" size="small" filterable clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[5].items,list[5],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount6 }}</td>
        </tr>
        <!-- 线宽小于139纳米的集成电路生产项目 -->
        <tr class="position_tr">
          <td :rowspan="(list[6].items.length + 2)" class="top_bg">七、线宽小于139纳米的集成电路生产项目</td>
          <td>
            <el-input size="small" v-model="list[6].items[0].projectName"></el-input>
          </td>
          <td class="bg_color"></td>
          <td class="bg_color"></td>
          <qzf-str-td v-model:amount="list[6].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[6].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[6].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[6].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[6].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[6].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[6].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[6].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[6].items,list[6],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[6].items,list[6],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[6].items" :key="$index" class="position_tr" >
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0" class="bg_color"></td>
          <td v-if="$index != 0" class="bg_color"></td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[6].items,list[6],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount7 }}</td>
        </tr>
        <!-- 线家小于65纳米或投资额超过150亿元的集成电路生产项目  -->
        <tr class="position_tr">
          <td :rowspan="(list[7].items.length + 2)" class="top_bg">八、线家小于65纳米或投资额超过150亿元的集成电路生产项目</td>
          <td>
            <el-input size="small" v-model="list[7].items[0].projectName"></el-input>
          </td>
          <td class="bg_color"></td>
          <td class="bg_color"></td>
          <qzf-str-td v-model:amount="list[7].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[7].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[7].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[7].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[7].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[7].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[7].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[7].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[7].items,list[7],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[7].items,list[7],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[7].items" :key="$index" class="position_tr">
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0" class="bg_color"></td>
          <td v-if="$index != 0" class="bg_color"></td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[7].items,list[7],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount8 }}</td>
        </tr>
        <!-- 线宽小于28纳米(含)的集成电路生产项目 -->
        <tr class="position_tr">
          <td :rowspan="(list[8].items.length + 2)" class="top_bg">九、线宽小于28纳米(含)的集成电路生产项目</td>
          <td>
            <el-input size="small" v-model="list[8].items[0].projectName"></el-input>
          </td>
          <td class="bg_color"></td>
          <td class="bg_color"></td>
          <qzf-str-td v-model:amount="list[8].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[8].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[8].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[8].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[8].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[8].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[8].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[8].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[8].items,list[8],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[8].items,list[8],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[8].items" :key="$index" class="position_tr" >
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0" class="bg_color"></td>
          <td v-if="$index != 0" class="bg_color"></td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[8].items,list[8],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount9 }}</td>
        </tr>
        <!-- 其他 -->
        <tr class="position_tr">
          <td :rowspan="(list[9].items.length + 2)" class="top_bg">十、其他</td>
          <td>
            <el-input size="small" v-model="list[9].items[0].projectName"></el-input>
          </td>
          <td class="bg_color"></td>
          <td class="bg_color"></td>
          <qzf-str-td v-model:amount="list[9].items[0].income" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[9].items[0].cost" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[9].items[0].tax" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[9].items[0].apportion" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[9].items[0].adjust" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td v-model:amount="list[9].items[0].dutyFree" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[9].items[0].halve" @change="changeList()"></qzf-str-td>
          <qzf-str-td v-model:amount="list[9].items[0].amount" :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <div class="add" @click="handleAddItem(list[9].items,list[9],0)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <div class="close" @click="handleDelItem(list[9].items,list[9],0)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr v-for="(item, $index) in list[9].items" :key="$index" class="position_tr" >
          <td v-if="$index != 0">
            <el-input size="small" v-model="item.projectName"></el-input>
          </td>
          <td v-if="$index != 0" class="bg_color"></td>
          <td v-if="$index != 0" class="bg_color"></td>
          <qzf-str-td v-model:amount="item.income" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.cost" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.tax" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.apportion" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.adjust" :disabled="expStatus" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.dutyFree" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.halve" @change="changeList()" v-if="$index != 0"></qzf-str-td>
          <qzf-str-td v-model:amount="item.amount" :disabled="expStatus" v-if="$index != 0" @change="changeList()"></qzf-str-td>
          <div class="close" @click="handleDelItem(list[9].items,list[9],$index)" v-if="$index != 0">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr class="bg_color center">
          <td>小计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ amount10 }}</td>
        </tr>
        <tr class="bg_color center">
          <td>合计</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>*</td>
          <td>{{ totalAmount }}</td>
        </tr>
      </table>
    </div>
  </div>
  
  </el-scrollbar>
  <div class="save_box">
    <el-button @click="saveDetail" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
  </div>
</el-dialog>

</template>

<script>
import { yearInfo, yearEdit } from "@/api/year"
export default {
  name: "A107020",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    }
  },
 
  data() {
    return {
      comName: '',
      taxNo: '',
      fullscreenLoading:false,
      dialogFormVisible:false,
      contentStyleObj:{},
      list:[{items:[{}]},{items:[{}]},{items:[{}]},{items:[{}]},{items:[{}]},{items:[{}]},{items:[{}]},{items:[{}]},{items:[{}]},{items:[{}]}],
      expStatus:false,
      productOption1:[{
        label:"0101|蔬菜、谷物、薯类、油料、豆类、棉花、麻类、糖料、水果、坚果的种植",
        value:"0101|蔬菜、谷物、薯类、油料、豆类、棉花、麻类、糖料、水果、坚果的种植"
      },
      {
        label:"0102|农作物新品种的选育",
        value:"0102|农作物新品种的选育"
      },
      {
        label:"0103|中药材的种植",
        value:"0103|中药材的种植"
      },
      {
        label:"0104|林木的培育和种植",
        value:"0104|林木的培育和种植"
      },
      {
        label:"0105|牲畜、家禽的饲养",
        value:"0105|牲畜、家禽的饲养"
      },
      {
        label:"0106|林产品的采集",
        value:"0106|林产品的采集"
      },
      {
        label:"0107|灌溉、兽医、农技推广、农机作业和维修等农、林、牧、渔服务业项目",
        value:"0107|灌溉、兽医、农技推广、农机作业和维修等农、林、牧、渔服务业项目"
      },
      {
        label:"0108|远洋捕捞",
        value:"0108|远洋捕捞"
      },
      {
        label:"0109|花卉、茶以及其他饮料作物和香料作物的种植",
        value:"0109|花卉、茶以及其他饮料作物和香料作物的种植"
      },
      {
        label:"0110|海水养殖、内陆养殖",
        value:"0110|海水养殖、内陆养殖"
      },
      {
        label:"0111|农产品初加工",
        value:"0111|农产品初加工"
      },
      {
        label:"0199|其他农林牧渔业项目",
        value:"0199|其他农林牧渔业项目"
      },
      ],
      productOption2:[
        {
          label:"0201|港口码头项目",
          value:"0201|港口码头项目"
        },
        {
          label:"0202|机场项目",
          value:"0202|机场项目"
        },
        {
          label:"0203|铁路项目",
          value:"0203|铁路项目"
        },
        {
          label:"0204|公路项目",
          value:"0204|公路项目"
        },
        {
          label:"0205|城市公共交通项目",
          value:"0205|城市公共交通项目"
        },
        {
          label:"0206|电力项目",
          value:"0206|电力项目"
        },
        {
          label:"0207|水利项目（不含农村饮水安全工程）",
          value:"0207|水利项目（不含农村饮水安全工程）"
        },
        {
          label:"0208|农村饮水安全工程",
          value:"0208|农村饮水安全工程"
        },
      ],
      productOption3:[
        {
          label:"0300|符合条件的环境保护、节能节水项目（2008）",
          value:"0300|符合条件的环境保护、节能节水项目（2008）",
          children:[
            {
              label:"0301|公共污水处理（2008）",
              value:"0301|公共污水处理（2008）",
            },
            {
              label:"0302|公共垃圾处理（2008）",
              value:"0302|公共垃圾处理（2008）",
            },
            {
              label:"0303|沼气综合开发利用（2008）",
              value:"0303|沼气综合开发利用（2008）",
            },
            {
              label:"0304|海水淡化（2008）",
              value:"0304|海水淡化（2008）",
            },
            {
              label:"0305|节能减排技术改造（2008）",
              value:"0305|节能减排技术改造（2008）",
            },
          ]
        },
        {
          label:"8000|符合条件的环境保护、节能节水项目（2021）",
          value:"8000|符合条件的环境保护、节能节水项目（2021）",
          children:[
            {
              label:"8100|环境污染防治（2021）",
              value:"8100|环境污染防治（2021）",
              children:[
                {
                  label:"8110|大气污染防治（2021）",
                  value:"8110|大气污染防治（2021）",
                  children:[
                    {
                      label:"8111|脱硫脱硝除尘排放治理及改造项目（2021）",
                      value:"8111|脱硫脱硝除尘排放治理及改造项目（2021）",
                    },
                    {
                      label:"8112|有机废气收集净化项目（2021）",
                      value:"8112|有机废气收集净化项目（2021）",
                    },
                    {
                      label:"8113|恶臭气体治理项目（2021）",
                      value:"8113|恶臭气体治理项目（2021）",
                    },
                  ]
                },
                {
                  label:"8120|公共污水处理（2021）",
                  value:"8120|公共污水处理（2021）",
                  children:[
                    {
                      label:"8121|城镇污水处理项目（2021）",
                      value:"8121|城镇污水处理项目（2021）",
                    },
                    {
                      label:"8122|工业废水处理项目（2021）",
                      value:"8122|工业废水处理项目（2021）",
                    },
                    {
                      label:"8123|农村污水处理项目（2021）",
                      value:"8123|农村污水处理项目（2021）",
                    },
                    {
                      label:"8124|污泥处理处置及资源化利用项目（2021）",
                      value:"8124|污泥处理处置及资源化利用项目（2021）",
                    },
                    {
                      label:"8125|水体修复与治理项目（2021）",
                      value:"8125|水体修复与治理项目（2021）",
                    },
                  ]
                },
                {
                  label:"8130|土壤与地下水污染治理（2021）",
                  value:"8130|土壤与地下水污染治理（2021）",
                  children:[
                    {
                      label:"8131|土壤与地下水污染修复项目（2021）",
                      value:"8131|土壤与地下水污染修复项目（2021）",
                    }
                  ]
                },
                {
                  label:"8140|公共垃圾处理（2021）",
                  value:"8140|公共垃圾处理（2021）",
                  children:[
                    {
                      label:"8141|生活垃圾分类和无害化处理处置项目（2021）",
                      value:"8141|生活垃圾分类和无害化处理处置项目（2021）",
                    },
                    {
                      label:"8142|工业固体废物利用处置项目（2021）",
                      value:"8142|工业固体废物利用处置项目（2021）",
                    },
                    {
                      label:"8143|危险废物利用处置项目（2021）",
                      value:"8143|危险废物利用处置项目（2021）",
                    },
                  ]
                },
                {
                  label:"8150|沼气综合开发利用（2021）",
                  value:"8150|沼气综合开发利用（2021）",
                  children:[
                    {
                      label:"8151|畜禽养殖场和养殖小区沼气工程项目（2021）",
                      value:"8151|畜禽养殖场和养殖小区沼气工程项目（2021）",
                    }
                  ]
                },
                {
                  label:"8160|生态环境检测项目（2021）",
                  value:"8160|生态环境检测项目（2021）",
                },
              ]
            },
            {
              label:"8200|节能减排技术改造（2021）",
              value:"8200|节能减排技术改造（2021）",
              children:[
                {
                  label:"8201|即有建筑节能与可再生能源利用项目（2021）",
                  value:"8201|即有建筑节能与可再生能源利用项目（2021）",
                },
                {
                  label:"8202|热泵技术改造项目（2021）",
                  value:"8202|热泵技术改造项目（2021）",
                },
                {
                  label:"8203|工业锅炉、工业窑炉节能改造项目（2021）",
                  value:"8203|工业锅炉、工业窑炉节能改造项目（2021）",
                },
                {
                  label:"8204|数据中心节能改造项目（2021）",
                  value:"8204|数据中心节能改造项目（2021）",
                },
                {
                  label:"8205|通信基站节能改造项目（2021）",
                  value:"8205|通信基站节能改造项目（2021）",
                },
                {
                  label:"8206|电机系统节能改造项目（2021）",
                  value:"8206|电机系统节能改造项目（2021）",
                },
                {
                  label:"8207|能量系统优化技术改造项目（2021）",
                  value:"8207|能量系统优化技术改造项目（2021）",
                },
                {
                  label:"8208|余热余压利用项目（2021）",
                  value:"8208|余热余压利用项目（2021）",
                },
                {
                  label:"8209|高效精馏设备和系统改造项目（2021）",
                  value:"8209|高效精馏设备和系统改造项目（2021）",
                },
                {
                  label:"8210|绿色照明项目（2021）",
                  value:"8210|绿色照明项目（2021）",
                },
                {
                  label:"8211|供热系统节能改造项目（2021）",
                  value:"8211|供热系统节能改造项目（2021）",
                },
                {
                  label:"8212|碳捕集、利用与封存（CCUS）项目（2021）",
                  value:"8212|碳捕集、利用与封存（CCUS）项目（2021）",
                },
              ]
            },
            {
              label:"8300|节水改造及非常规水利用（2021）",
              value:"8300|节水改造及非常规水利用（2021）",
              children:[
                {
                  label:"8301|海水淡化项目（2021）",
                  value:"8301|海水淡化项目（2021）",
                },
                {
                  label:"8302|污水资源化利用项目（2021）",
                  value:"8302|污水资源化利用项目（2021）",
                },
                {
                  label:"8303|城镇和工业公共供水管网改造项目（2021）",
                  value:"8303|城镇和工业公共供水管网改造项目（2021）",
                },
                {
                  label:"8304|工业节水改造项目（2021）",
                  value:"8304|工业节水改造项目（2021）",
                },
              ]
            },
          ]
        },
      ],
      productOption4:[
        {
          label:"0403|中关村国家自主创新示范区特定区域技术转让项目",
          value:"0403|中关村国家自主创新示范区特定区域技术转让项目",
        },
        {
          label:"0402|一般技术转让项目",
          value:"0402|一般技术转让项目",
        },
      ],
      productOption5:[
        {
          label:"实施清洁发展机制项目",
          value:"实施清洁发展机制项目",
        },
      ],
      productOption6:[{
        label:"符合条件的节能服务公司实施合同能源管理项目",
        value:"符合条件的节能服务公司实施合同能源管理项目",
      }],
      productOption7:[],
      productOption8:[],
      productOption9:[],
      productOption10:[],
      options:[
        {
          label:"免税",
          value:"免税"
        },
        {
          label:"减半征收",
          value:"减半征收"
        }
      ],
      amount1:0,
      amount2:0,
      amount3:0,
      amount4:0,
      amount5:0,
      amount6:0,
      amount7:0,
      amount8:0,
      amount9:0,
      amount10:0,
      totalAmount:0
    };
  },
  computed: {
    amount1(){
      let count = 0;
      this.list[0].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount2(){
      let count = 0;
      this.list[1].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount3(){
      let count = 0;
      this.list[2].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount4(){
      let count = 0;
      this.list[3].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount5(){
      let count = 0;
      this.list[4].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount6(){
      let count = 0;
      this.list[5].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount7(){
      let count = 0;
      this.list[6].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount8(){
      let count = 0;
      this.list[7].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount9(){
      let count = 0;
      this.list[8].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    amount10(){
      let count = 0;
      this.list[9].items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      });
      return count.toFixed(2);
    },
    totalAmount(){
      let count = 0
      this.list.map(v=>{
        v.items.map(e=>{
          if(e.amount){
            count += Number((e.amount*1).toFixed(2))
          }
        })
      })
      return count.toFixed(2);
    }
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(230)
  },
  methods: {
    getParams() {
      this.dialogFormVisible = true
      const tableName = 'year_a107020'
      this.tableName = tableName
      if (tableName) {
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName});
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    getDetail(e) {
      yearInfo(e).then(response => {
        if(response.data.data){
          this.list = response.data.data;
        }else{
          this.$qzfMessage('数据有误，请重新取数',1)
        }
      });
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      yearEdit({comId:this.comId,year:this.startAccountPeriod,tableName:this.tableName,items:this.list}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.fullscreenLoading = false
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName})
      });
    },
    handleAddItem(item,parent, index) {
      item.push({
        projectName:"",
        projectItem:"",
        mode:"",
        income:"",
        cost:"",
        tax:"",
        apportion:"",
        adjust:"",
        dutyFree:"",
        halve:"",
        amount:"",
        parentId:parent.id
      });
    },
    handleAddItem5(item,parent, index){
      item.push({
        projectName:"",
        projectItem:"实施清洁发展机制项目",
        mode:"",
        income:"",
        cost:"",
        tax:"",
        apportion:"",
        adjust:"",
        dutyFree:"",
        halve:"",
        amount:"",
        parentId:parent.id
      });
    },
    handleAddItem6(item,parent, index){
      item.push({
        projectName:"",
        projectItem:"符合条件的节能服务公司实施合同能源管理项目",
        mode:"",
        income:"",
        cost:"",
        tax:"",
        apportion:"",
        adjust:"",
        dutyFree:"",
        halve:"",
        amount:"",
        parentId:parent.id
      });
    },
    handleDelItem(item,parent,index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    changeList(){
      // this.amount1 = 0
      // this.list[0].items.map(v=>{
      //   this.amount1 += Number(Number(v.amount).toFixed(2))
      // })
    },
    //第一项
    changeProduct1(item){
      item.mode = ""
      if(item.projectItem == '0109|花卉、茶以及其他饮料作物和香料作物的种植' || item.projectItem == '0110|海水养殖、内陆养殖'){
        item.options = [{label:"减半征收",value:"减半征收"}]
      }else if (item.projectItem == '0199|其他农林牧渔业项目'){
        item.options = [
          {label:"减半征收",value:"减半征收"},
          {label:"免税",value:"免税"}
        ]
      }else{
        item.options = [{label:"免税",value:"免税"}]
      }
    },
    //第三项多选
    changeProduct3(item){
      item.projectItem = item.projectItem[item.projectItem.length - 1]
    }
    
  }
};
</script>

<style lang="scss" scoped>
.new_input input{
  text-align: left !important;
}
.big_box4{
  padding: 0 15px;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -20px;
    font-size: 20px;
    color: #17a2b8;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: 0em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  width: 97%;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin: 0 auto;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  width: 97%;
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
  padding-right: 15px;
}
.position_tr{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    margin-left: 5px;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.cent {
  display: flex;
}
</style>