<template>
  <!-- 企业会计制度 利润表-->
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:46%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;">上年金额（税局预填）</td>
          <!-- <td class="center" style="width:13%;">本年累计</td> -->
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}"> 
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:46%;">一、主营业务收入</td>
            <td class="center bg_color" style="width:4%;">1</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[0].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：主营业务成本</td>
            <td class="center bg_color">2</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[1].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">主营业务税⾦及附加</td>
            <td class="center bg_color">3</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[2].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color">⼆、主营业务利润（亏损以“－”号填列）(1-2-3)</td>
            <td class="center bg_color">4</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[3].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：其他业务利润（亏损以“－”号填列</td>
            <td class="center bg_color">5</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[4].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>

          <tr>
            <td class="left_k bg_color">减：营业费⽤</td>
            <td class="center bg_color">6</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[5].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>

          <tr>
            <td class="left_b bg_color">管理费⽤</td>
            <td class="center bg_color">7</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[6].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">财务费⽤</td>
            <td class="center bg_color">8</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[7].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color">三、营业利润（亏损以“－”号填列）(4+5-6-7-8)</td>
            <td class="center bg_color">9</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[8].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：投资收益（损失以“－”号填列）</td>
            <td class="center bg_color">10</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[9].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">补贴收⼊</td>
            <td class="center bg_color">11</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[10].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">营业外收⼊</td>
            <td class="center bg_color">12</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[11].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业外⽀出</td>
            <td class="center bg_color">13</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[12].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color">四、利润总额（亏损总额以“－”号填列）(9+10+11+12-13)</td>
            <td class="center bg_color">14</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[13].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：所得税</td>
            <td class="center bg_color">15</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[14].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color">五、净利润（净亏损以“－”号填列）(14-15)</td>
            <td class="center bg_color">16</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[15].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">1．出售、处置部⻔或被投资单位所得收益</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[16].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">2．⾃然灾害发⽣的损失</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[17].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">3．会计政策变更增加（或减少）利润总额</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[18].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">4、会计估计变更增加（或减少）利润总额</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[19].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">5、债务重组损失</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[20].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">6、其他</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[21].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
        </table>
        </el-scrollbar>
      <!-- </div> -->
    </div>
    <div class="save_box">
      <el-button @click="saveLrb" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
  
</template>

<script>
import { yearInfo, yearEdit } from '@/api/year'

export default {
  name: 'lrbYTable3',
  components: { },
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      comName:'',
      taxNo:'',
      attachArr: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(400)
  },
  methods: {
    changeList(){
    },
    getList() {
      yearInfo({comId:this.comId,period:this.startAccountPeriod,tableName:'year_lrb'}).then(res => {
        if(res.data.data){
          this.attachArr = res.data.data;
        }
      });
    },
    saveLrb(){
      this.attachArr.map(v=>{
        v.yearTotal = Number(v.yearTotal)
      })
      yearEdit({comId:this.comId,period:this.startAccountPeriod,tableName:'year_lrb',items:this.attachArr}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
          this.getList()
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
 // margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 13px;
    padding-right:20px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
