<template>
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h4>利润表(年报)</h4>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:46%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;">上年金额（税局预填）</td>
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">  
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight center bg_color" style="width:46%;">一、营业收入</td>
            <td class="center bg_color" style="width:4%;">1</td>
            <td style="width:25%;"><input type="text" v-model="attachArr[0].yearTotal"></td>
            <!-- <td class="top_bg" style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>

          </tr>
          <tr>
            <td class="bg_color">减：营业成本</td>
            <td class="center bg_color">2</td>
            <td><input type="text" v-model="attachArr[1].yearTotal"></td>
            <!-- <td><input type="text" v-model="attachArr[1].lastYearTotal"></td> -->
            <td class="bg_color"></td>

          </tr>
          <tr>
            <td class="bg_color">税金及附加</td>
            <td class="center bg_color">3</td>
            <td><input type="text" v-model="attachArr[2].yearTotal"></td>
            <!-- <td><input type="text" v-model="attachArr[2].lastYearTotal"></td> -->
            <td class="bg_color"></td>

          </tr>
          <tr>
            <td class="bg_color">其中：消费税</td>
            <td class="center bg_color">4</td>
            <td><input type="text" v-model="attachArr[3].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[3].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">营业税</td>
            <td class="center bg_color">5</td>
            <td><input type="text" v-model="attachArr[4].yearTotal"></td>
            <td class="bg_color"></td>
            
            <!-- <td><input type="text" v-model="attachArr[4].lastYearTotal"></td> -->
          </tr>

          <tr>
            <td class="bg_color">城市维护建设税</td>
            <td class="center bg_color">6</td>
            <td><input type="text" v-model="attachArr[5].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[5].lastYearTotal"></td> -->
          </tr>

          <tr>
            <td class="bg_color">资源税</td>
            <td class="center bg_color">7</td>
            <td><input type="text" v-model="attachArr[6].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[6].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">土地增值税</td>
            <td class="center bg_color">8</td>
            <td><input type="text" v-model="attachArr[7].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[7].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">城镇土地使用税、房产税、车船税、印花税</td>
            <td class="center bg_color">9</td>
            <td><input type="text" v-model="attachArr[8].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[8].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">教育附加、矿产资源、排污费</td>
            <td class="center bg_color">10</td>
            <td><input type="text" v-model="attachArr[9].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[9].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">销售费用</td>
            <td class="center bg_color">11</td>
            <td><input type="text" v-model="attachArr[10].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[10].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">其中：商品维修费</td>
            <td class="center bg_color">12</td>
            <td><input type="text" v-model="attachArr[11].yearTotal"></td>
            <td class="bg_color"></td>
            
            <!-- <td><input type="text" v-model="attachArr[11].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">广告费和业务宣传费</td>
            <td class="center bg_color">13</td>
            <td><input type="text" v-model="attachArr[12].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[12].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">管理费用</td>
            <td class="center bg_color">14</td>
            <td><input type="text" v-model="attachArr[13].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[13].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">其中：开办费</td>
            <td class="center bg_color">15</td>
            <td><input type="text" v-model="attachArr[14].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[14].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">业务招待费</td>
            <td class="center bg_color">16</td>
            <td><input type="text" v-model="attachArr[15].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[15].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">研究费用</td>
            <td class="center bg_color">17</td>
            <td><input type="text" v-model="attachArr[16].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[16].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">财务费用</td>
            <td class="center bg_color">18</td>
            <td><input type="text" v-model="attachArr[17].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[17].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">其中：利息费用(收入以-号填列)</td>
            <td class="center bg_color">19</td>
            <td><input type="text" v-model="attachArr[18].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[18].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">加：投资收益</td>
            <td class="center bg_color">20</td>
            <td><input type="text" v-model="attachArr[19].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[19].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="weight center bg_color">二、营业利润</td>
            <td class="center bg_color">21</td>
            <td><input type="text" v-model="attachArr[20].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[20].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">加：营业外收入</td>
            <td class="center bg_color">22</td>
            <td><input type="text" v-model="attachArr[21].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[21].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">其中：政府补助</td>
            <td class="center bg_color">23</td>
            <td><input type="text" v-model="attachArr[22].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[22].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">减：营业外支出</td>
            <td class="center bg_color">24</td>
            <td><input type="text" v-model="attachArr[23].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[23].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">其中：坏账损失</td>
            <td class="center bg_color">25</td>
            <td><input type="text" v-model="attachArr[24].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[24].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">无法收回的长期债券投资损失</td>
            <td class="center bg_color">26</td>
            <td><input type="text" v-model="attachArr[25].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[25].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">无法收回的长期股权投资损失</td>
            <td class="center bg_color">27</td>
            <td><input type="text" v-model="attachArr[26].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[26].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">自然灾害等不可抗力因素造成的损失</td>
            <td class="center bg_color">28</td>
            <td><input type="text" v-model="attachArr[27].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[27].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">税收滞纳金</td>
            <td class="center bg_color">29</td>
            <td><input type="text" v-model="attachArr[28].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[28].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="weight center bg_color">三、利润总额</td>
            <td class="center bg_color">30</td>
            <td><input type="text" v-model="attachArr[29].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[29].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="bg_color">减：所得税费用</td>
            <td class="center bg_color">31</td>
            <td><input type="text" v-model="attachArr[30].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[30].lastYearTotal"></td> -->
          </tr>
          <tr>
            <td class="weight center bg_color">四、净利润</td>
            <td class="center bg_color">32</td>
            <td><input type="text" v-model="attachArr[31].yearTotal"></td>
            <td class="bg_color"></td>

            <!-- <td><input type="text" v-model="attachArr[31].lastYearTotal"></td> -->
          </tr>
        </table>
       </el-scrollbar>
      <!-- </div> -->
    </div>
    <div class="save_box">
      <el-button @click="saveLrb" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
</template>

<script>
import { yearInfo, yearEdit } from '@/api/year'

export default {
  name:'lrbYTable',
  props:{
    title:String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      attachArr: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(350)
  },
  methods: {
    changeList(){
      
    },
    getList() {
      yearInfo({comId:this.comId,period:this.startAccountPeriod,tableName:'year_lrb'}).then(res => {
        if(res.data.data){
          this.attachArr = res.data.data;
        }
      });
    },
    saveLrb(){
      this.attachArr.map(v=>{
        v.yearTotal = Number(v.yearTotal)
      })
      yearEdit({comId:this.comId,period:this.startAccountPeriod,tableName:'year_lrb',items:this.attachArr}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
          this.getList()
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
 // margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 3px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
</style>
