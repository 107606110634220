<template>
  <div>
    <el-dialog :close-on-click-modal="false"
      v-model="dialogFormVisible"
      :title="name"
      width="90%"
      destroy-on-close
      >
      <yearFinance :comId="comId" tableYearName="year_zcfz" ref="yearFinance" :startAccountPeriod="this.yearTime" :type="type"></yearFinance>
    </el-dialog>
  </div>
</template>

<script>
import yearFinance from "@/components/yearFinance/fianceYear"; //列表组件

export default {
  name: 'checkCwbbYear',
  components:{
    yearFinance
  },
  props: {
    yearTime:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      dialogFormVisible:false,
      comId:0,
      type:''
    };
  },

  mounted() {
    
  },

  methods: {
    getList(e){
      this.comId = e.comId
      this.type = e.ComKj
      this.dialogFormVisible =true
    }
  },
};
</script>

<style lang="scss" scoped>

</style>