<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="name"
    width="90%"
    destroy-on-close
    >
    <div v-loading.fullscreen.lock="fullscreenLoading">
      <!-- 资产折旧、摊销及纳税调整明细表 -->
      <div class="big_box4">
       <el-scrollbar :height="contentStyleObj">
        <div class="top_title3">
          <h4>资产折旧、摊销及纳税调整明细表</h4>
          <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
            <h6 class="center"><span></span></h6>
            <h6 class="right">
              <p>单位：元</p>
              <p>纳税人识别号：<span>{{taxNo}}</span></p>
            </h6>
          </div> -->
        </div>
        <table class="content" cellspacing="0" width="100%" style="margin-top: 10px;">
          <tr class="top_bg">
            <td class="center" colspan="7">税收优惠基本信息</td>
          </tr>
        </table>
        <div class="fixed_box">
          <table class="content" cellspacing="0" style="border-top: none;">
            <tr>
              <td class="center" style="width:4%;">1</td>
              <td colspan='4' rowspan= '3'  class="center" style="width:36%">企业主要产品(服务)发挥核心支持作用的技术所属范围</td>
              <td colspan='3' rowspan= '3' class="center" style="width:26%">国家重点支持的高新技术领域</td>
              <td class="center" >一级领域</td>
              <td class="blue_box" style="width:15%">
                <el-select v-model="info[0].infoData" placeholder="请选择" @change="changeLevelOne" clearable filterable class="boxShadowNone" style="margin: 0px;">
                  <el-option
                    v-for="item in selectOption"
                    :key="item.jc"
                    :label="item.data"
                    :value="item.jc">
                  </el-option>
                </el-select>
                <!-- <input type="text" v-model="info[0].infoData"> -->
              </td>
            </tr>
            <tr>
              <td class="center" >2</td>
              <td class="center" >二级领域</td>
              <td class="blue_box" >
                <el-select v-model="info[1].infoData" placeholder="请选择" @change="changeLevelSecond"  clearable filterable class="boxShadowNone" style="margin: 0px;">
                  <el-option
                    v-for="item in selectOptionSecond"
                    :key="item.jc"
                    :label="item.data"
                    :value="item.jc">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td class="center" >3</td>
              <td class="center" >三级领域</td>
              <td class="blue_box" >
                <el-select v-model="info[2].infoData" placeholder="请选择" clearable filterable class="boxShadowNone" style="margin: 0px;">
                  <el-option
                    v-for="item in selectOptionThird"
                    :key="item.jc"
                    :label="item.data"
                    :value="item.jc">
                  </el-option>
                </el-select>
              </td>
            </tr>
          </table>
        </div>
        <table class="content" cellspacing="0" width="100%" style="border-top: none;">
          <tr class="top_bg">
            <td class="center" colspan="7">税收优惠有关情况</td>
          </tr>
        </table>
        <div class="fixed_box">
          <table class="content" cellspacing="0" style="border-top: none;">
            <tr>
              <td class="center" style="width:4%;">4</td>
              <td rowspan= '7' class="center" >收入指标</td>
              <td colspan="4" >一、本年高新技术产品(服务) 收入 (5+6)</td>
              <td class="blue_box" style="width:15%"><input type="text" v-model="info[3].infoData"></td>
            </tr>
            <tr>
              <td class="center" >5</td>
              <td colspan="4">其中: 产品 (服务) 收入</td>
              <td class="blue_box"><input type="text" v-model="info[4].infoData"></td>
            </tr>
            <tr>
              <td class="center" >6</td>
              <td  colspan="4"> 技术性收入</td>
              <td class="blue_box"><input type="text" v-model="info[5].infoData"></td>
            </tr>
            <tr>
              <td class="center" >7</td>
              <td  colspan="4">  二、本年企业总收入(8-9)</td>
              <td class="blue_box"><input type="text" v-model="info[6].infoData"></td>
            </tr>
            <tr>
              <td class="center" >8</td>
              <td  colspan="4">其中:收入总额</td>
              <td class="blue_box"><input type="text" v-model="info[7].infoData"></td>
            </tr>
            <tr>
              <td class="center" >9</td>
              <td colspan="4"> 不征税收入</td>
              <td class="blue_box"><input type="text" v-model="info[8].infoData"></td>
            </tr>
            <tr>
              <td class="center" >10</td>
              <td colspan="4">  三、本年高新技术产品(服务)收入占企业总收入的比例(4+7)</td>
              <td class="blue_box"><input type="text" v-model="info[9].infoData"></td>
            </tr>
            <tr>
              <td class="center">11</td>
              <td rowspan= '3' class="center">人员指标</td>
              <td colspan="4">四、本年科技人员数</td>
              <td class="blue_box" ><input type="text" v-model="info[10].infoData"></td>
            </tr>
            <tr>
              <td class="center" >12</td>
              <td colspan="4">五、本年职工总数</td>
              <td class="blue_box"><input type="text" v-model="info[11].infoData"></td>
            </tr>
            <tr>
              <td class="center" >13</td>
              <td colspan="4">六、本年科技人员占企业当年职工总数的比例(11-12)</td>
              <td class="blue_box"><input type="text" v-model="info[12].infoData"></td>
            </tr>
            <tr>
              <td class="center">14</td>
              <td rowspan= '18' class="center">研究开发费用指标</td>
              <td rowspan= '2' class="center"  >高新研发费用归集年度</td>
              <td>本年度</td>
              <td>前一年度</td>
              <td>前二年度</td>
              <td>合计</td>
            </tr>
            <tr>
              <td class="center" ></td>
              <td class="center" >1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
            </tr>
            <tr>
              <td class="center" >15</td>
              <td>七、归集的高新研发费用金额(16+25)</td>
              <td class="blue_box"><input type="text" v-model="info[14].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[14].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[14].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[14].a74"></td>
            </tr>
            <tr>
              <td class="center" >16</td>
              <td>(一)内部研究开发投入(17+...+22+24)</td>
              <td class="blue_box"><input type="text" v-model="info[15].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[15].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[15].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[15].a74"></td>
            </tr>
            <tr>
              <td class="center" >17</td>
              <td>1.人员人工费用</td>
              <td class="blue_box"><input type="text" v-model="info[16].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[16].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[16].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[16].a74"></td>
            </tr>
            <tr>
              <td class="center" >18</td>
              <td>2.直投用入费用</td>
              <td class="blue_box"><input type="text" v-model="info[17].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[17].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[17].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[17].a74"></td>
            </tr>
            <tr>
              <td class="center" >19</td>
              <td>3.折旧费用与长期待摊费用</td>
              <td class="blue_box"><input type="text" v-model="info[18].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[18].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[18].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[18].a74"></td>
            </tr>
            <tr>
              <td class="center" >20</td>
              <td>4.无形资产摊销费用</td>
              <td class="blue_box"><input type="text" v-model="info[19].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[19].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[19].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[19].a74"></td>
            </tr>
            <tr>
              <td class="center" >21</td>
              <td>5.设计费用</td>
              <td class="blue_box"><input type="text" v-model="info[20].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[20].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[20].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[20].a74"></td>
            </tr>
            <tr>
              <td class="center" >22</td>
              <td>6.装备调试费与实验费用</td>
              <td class="blue_box"><input type="text" v-model="info[21].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[21].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[21].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[21].a74"></td>
            </tr>
            <tr>
              <td class="center" >23</td>
              <td>7.其他费用</td>
              <td class="blue_box"><input type="text" v-model="info[22].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[22].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[22].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[22].a74"></td>
            </tr>
            <tr>
              <td class="center" >24</td>
              <td>其中:可计入研发费用的其他费用</td>
              <td class="blue_box"><input type="text" v-model="info[23].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[23].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[23].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[23].a74"></td>
            </tr>
            <tr>
              <td class="center" >25</td>
              <td>(二)委托外部研究开发费用[(26+28)x80%]</td>
              <td class="blue_box"><input type="text" v-model="info[24].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[24].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[24].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[24].a74"></td>
            </tr>
            <tr>
              <td class="center" >26</td>
              <td>1.境内的外部研发费</td>
              <td class="blue_box"><input type="text" v-model="info[25].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[25].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[25].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[25].a74"></td>
            </tr>
            <tr>
              <td class="center" >27</td>
              <td>2.境外的外部研发费</td>
              <td class="blue_box"><input type="text" v-model="info[26].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[26].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[26].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[26].a74"></td>
            </tr>
            <tr>
              <td class="center" >28</td>
              <td>其中:可计入研发费用的境外的外部研发费</td>
              <td class="blue_box"><input type="text" v-model="info[27].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[27].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[27].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[27].a74"></td>
            </tr>
            <tr>
              <td class="center" >29</td>
              <td>八、销售(营业)收入</td>
              <td class="blue_box"><input type="text" v-model="info[28].a71"></td>
              <td class="blue_box"><input type="text" v-model="info[28].a72"></td>
              <td class="blue_box"><input type="text" v-model="info[28].a73"></td>
              <td class="blue_box"><input type="text" v-model="info[28].a74"></td>
            </tr>
            <tr>
              <td class="center" >30</td>
              <td colspan="4">九、三年研发费用占销售(营业)收入比例(15行4列-29行4列)</td>
              <td class="blue_box"><input type="text" v-model="info[29].infoData"></td>
            </tr>
            <tr>
              <td class="center" >31</td>
              <td class="center" rowspan="2">减免税额</td>
              <td colspan="4">十、国家需要重点扶持的高新技术企业减征企业所得税</td>
              <td class="blue_box"><input type="text" v-model="info[30].infoData"></td>
            </tr>
            <tr>
              <td class="center" >32</td>
              <td colspan="4">十一、经济特区和上海浦东新区新设立的高新技术企业定期减免税额</td>
              <td class="blue_box"><input type="text" v-model="info[31].infoData"></td>
            </tr>


          </table>
        </div>
        </el-scrollbar>
        <div class="save_box">
          <el-button type="primary" size="small" @click="saveDetail()" :disabled="!$buttonStatus('hsqj_bbbc')"><i class="iconfont icon-baocun"></i>保存</el-button>
        </div>
      </div>
      
      <!-- 资产折旧、摊销及纳税调整明细表 end -->
    </div>
  </el-dialog>


</template>
<script>
import { yearInfo, yearEdit ,yearASelect } from "@/api/year"
export default {
  name: "A107041",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    }
  },
  data() {
    return {
      comName: '',
      taxNo: '',
      info:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      fullscreenLoading:false,
      dialogFormVisible:false,
      parentJc:'1',
      selectOption:[],
      selectOptionSecond:[],
      selectOptionThird:[],
      contentStyleObj:{}
    };
  },
  created() {
    this.comName = this.$store.getters['user/comInfo'].comName
    this.taxNo = this.$store.getters['user/comInfo'].taxNo
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(210)
  },
  methods: {
    getParams() {
      this.dialogFormVisible = true
      // const tableName = this.$route.params.tableName;
      const tableName = 'year_a107041'
      this.tableName = tableName
      if (tableName) {
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName});
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    getDetail(e) {
      yearInfo(e).then(res => {
        if(res.data.data){
          this.info = res.data.data;
          if(this.info[0].infoData && this.info[1].infoData){
            yearASelect({parentJc:this.info[0].infoData}).then(res => {
              if(res.data.msg =="success"){
                this.selectOptionSecond = res.data.data
              }
            })
            yearASelect({parentJc:this.info[1].infoData}).then(res => {
              if(res.data.msg =="success"){
                this.selectOptionThird = res.data.data
              }
            })
          }else if(this.info[0].infoData){
            yearASelect({parentJc:this.info[0].infoData}).then(res => {
              if(res.data.msg =="success"){
                this.selectOptionSecond = res.data.data
              }
            })
          }
        }
      });
      yearASelect({parentJc:this.parentJc}).then(res => {
        if(res.data.msg =="success"){
          this.selectOption = res.data.data
        }
      })
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      yearEdit({comId:this.comId,year:this.startAccountPeriod,tableName:this.tableName,items:this.info}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.fullscreenLoading = false
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName})
      });
    },
    changeLevelOne(val){
      yearASelect({parentJc:val}).then(res => {
        if(res.data.msg =="success"){
          this.selectOptionSecond = res.data.data
        }
      })
    },
    changeLevelSecond(val){
      yearASelect({parentJc:val}).then(res => {
        if(res.data.msg =="success"){
          this.selectOptionThird = res.data.data
        }
      })
    }
    
  }
};
</script>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
.border_none .el-select{
  width: 100% !important;
}
.center{
  text-align: center;
}
.right{
  text-align: right;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    // padding: 0 6px;
    font-size: 12px;
    text-align: right;
  }
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 5em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>



