<template>
  <div>
    <!-- <span style="font-size:13px;color: red;margin-left: 50%;">*累计金额系统不会取数,申报完成会抓取税局最新金额</span> -->
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:46%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" >上年金额</td>
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">  
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight center bg_color" style="width:46%;">一、经营活动产生的现金流量：</td>
            <td class="center" style="width:4%;"></td>
            <td style="width:25%;"></td>
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">销售产成品、商品、提供劳务收到的现金</td>
            <td class="center bg_color">1</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[0].yearTotal"></td>
            <!-- <td class="top_bg" style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">收到其他与经营活动有关的现金</td>
            <td class="center bg_color">2</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[1].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">购买原材料、商品、接受劳务支付的现金</td> 
            <td class="center bg_color">3</td> 
            <td style="width:25%;"><input type="text" v-model="xjllbList[2].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">支付的职工薪酬</td>
            <td class="center bg_color">4</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[3].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>

          <tr>
            <td class="bg_color">支付的税费</td>
            <td class="center bg_color">5</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[4].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>

          <tr>
            <td class="bg_color">支付其他与经营活动有关的现金</td>
            <td class="center bg_color">6</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[5].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">经营活动产生的现金流量净额</td>
            <td class="center bg_color">7</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[6].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>


          <tr>
            <td class="weight center bg_color">二、投资活动产生的现金流量：</td>
            <td class="center"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="bg_color">收回短期投资、长期债券投资和长期股权投资收到的现金</td>
            <td class="center bg_color">8</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[7].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">取得投资收益收到的现金</td>
            <td class="center bg_color">9</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[8].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">处置固定资产、无形资产和其他非流动资产收回的现金净额</td>
            <td class="center bg_color">10</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[9].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">短期投资、长期债券投资和长期股权投资支付的现金</td>
            <td class="center bg_color">11</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[10].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>

          <tr>
            <td class="bg_color">购建固定资产、无形资产和其他非流动资产支付的现金</td>
            <td class="center bg_color">12</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[11].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>

          <tr>
            <td class="bg_color">投资活动产生的现金流量净额</td>
            <td class="center bg_color">13</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[12].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>


          <tr>
            <td class="weight center bg_color">三、筹资活动产生的现金流量：</td>
            <td class="center"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="bg_color">取得借款收到的现金</td>
            <td class="center bg_color">14</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[13].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">吸收投资者投资收到的现金</td>
            <td class="center bg_color">15</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[14].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">偿还借款本金支付的现金</td>
            <td class="center bg_color">16</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[15].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">偿还借款利息支付的现金</td>
            <td class="center bg_color">17</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[16].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>

          <tr>
            <td class="bg_color">分配利润支付的现金</td>
            <td class="center bg_color">18</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[17].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>

          <tr>
            <td class="bg_color">筹资活动产生的现金流量净额</td>
            <td class="center bg_color">19</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[18].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>

          <tr>
            <td class="weight center bg_color">四、现金净增加额</td>
            <td class="center bg_color">20</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[19].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="bg_color">加：期初现金余额</td>
            <td class="center bg_color">21</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[20].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
          <tr>
            <td class="weight center bg_color">五、期末现金余额</td>
            <td class="center bg_color">22</td>
            <td style="width:25%;"><input type="text" v-model="xjllbList[21].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;"></td>
          </tr>
        </table>
       </el-scrollbar>
      <!-- </div> -->
    </div>

    <div class="save_box" v-if="this.typeName != '历史数据'&& $buttonStatus('kjbb_bc')">
      <qzf-button button_code="xjllb_bc" @success="saveXjllb" type="primary" size="small" >
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
  </div>
</template>

<script>
import { yearInfo, yearEdit } from '@/api/year'

export default {
  name:'cashTable',
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      comName:'',
      taxNo:'',
      xjllbList: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化

    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(350)
  },
  methods:{
    changeList(){

    },
    getList(){
      yearInfo({comId:this.comId,period:this.startAccountPeriod,tableName:'year_xjllb'}).then(res => {
        if(res.data.data.length == 22){
          this.xjllbList = res.data.data;
        }
      });
    },
    saveXjllb(){
      this.xjllbList.map(v=>{
        v.lastYearTotal = Number(v.lastYearTotal)
        v.yearTotal = Number(v.yearTotal)
      })
      yearEdit({comId:this.comId,period:this.startAccountPeriod,tableName:'year_xjllb',items:this.xjllbList}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
          this.getList()
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
 // margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  } 
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}

.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>