<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="name"
    width="90%"
    destroy-on-close
    >
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="big_box4">
     <el-scrollbar :height="contentStyleObj">
      <div class="top_title3">
        <h4>视同销售和房地产开发企业特定业务纳税调整明细表</h4>
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
         <td class="center" style="width:4%;" rowspan= '2'>行次</td>
         <td class="center" style="width:56%;" rowspan= '2'>项目</td>
         <td class="center" style="width:20%;">税收金额</td>
         <td class="center" style="width:20%;">纳税调整金额</td>
        </tr>
        <tr class="top_bg">
          <td class="center">1</td>
          <td class="center">2</td>
        </tr>
        <tr v-for="item in info" :key="item.id">
          <td class="center">{{item.type}}</td>
          <td>{{item.project}}</td>
          <td class="blue_box" v-if="item.a1 != '*'"><input type="text" v-model="item.a1"></td>
          <td class="gary_box center" v-else>{{item.a1}}</td>
          <td class="blue_box" v-if="item.a2 != '*'"><input type="text" v-model="item.a2"></td>
          <td class="gary_box center" v-else>{{item.a2}}</td>
        </tr>
      </table>
      </el-scrollbar>
      <div class="save_box">
        <el-button type="primary" size="small" @click="saveDetail()" :disabled="!$buttonStatus('hsqj_bbbc')"><i class="iconfont icon-baocun"></i>保存</el-button>
      </div>
    </div>
    
    <!-- 视同销售和房地产开发企业特定业务纳税调整明细表 end -->
  </div>
</el-dialog>

</template>
<script>
import { yearInfo, yearEdit } from "@/api/year"
export default {
  name: "A105010",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    name:{
      default:'',
      type:String
    }
  },
  data() {
    return {
      comName: '',
      taxNo: '',
      info:[],
      fullscreenLoading:false,
      dialogFormVisible:false,
      contentStyleObj:{}
    };
  },
  created() {
    this.comName = this.$store.getters['user/comInfo'].comName
    this.taxNo = this.$store.getters['user/comInfo'].taxNo
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(210)
  },
  methods: {
    getParams() {
      this.dialogFormVisible = true
      const tableName = 'year_a105010'
      this.tableName = tableName
      if (tableName) {
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName});
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    getDetail(e) {
      yearInfo(e).then(response => {
        this.info = response.data.data;
      });
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      yearEdit({comId:this.comId,year:this.startAccountPeriod,tableName:this.tableName,items:this.info}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.getDetail({comId:this.comId,period:this.startAccountPeriod,tableName:this.tableYearName})
        this.fullscreenLoading = false
      });
    }
    
  }
};
</script>

<style lang="scss" scoped>
.gary_box{
  background: #f8f8f8;
  text-align: right;
  padding-right: 20px !important;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    text-align: right;
    font-size: 12px;
  }
}
.center{
  text-align: center;
}
.big_box4{
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
</style>



