<template>
  <!-- 企业会计制度 利润表-->
  <div>
    <div class="big_box4">
      <div class="top_title3">
        <h4>利润统计表（适用已执行新金融准则的一般企业）</h4>
        <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span></span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:46%;">项目</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;">上年金额（税局预填）</td>
          <!-- <td class="center" style="width:13%;">本年累计</td> -->

        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:46%;">一、营业收入</td>
            <td class="center bg_color" style="width:4%;">1</td>
            <td style="width:25%;"><input type="text" v-model="list[0].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业成本</td>
            <td class="center bg_color">2</td>
            <td style="width:25%;"><input type="text" v-model="list[1].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">税金及附加</td>
            <td class="center bg_color">3</td>
            <td style="width:25%;"><input type="text" v-model="list[2].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">销售费用</td>
            <td class="center bg_color">4</td>
            <td style="width:25%;"><input type="text" v-model="list[3].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">管理费用</td>
            <td class="center bg_color">5</td>
            <td style="width:25%;"><input type="text" v-model="list[4].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">研发费用</td>
            <td class="center bg_color">6</td>
            <td style="width:25%;"><input type="text" v-model="list[5].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_b bg_color">财务费用</td>
            <td class="center bg_color">7</td>
            <td style="width:25%;"><input type="text" v-model="list[6].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">其中：利息费用</td>
            <td class="center bg_color">8</td>
            <td style="width:25%;"><input type="text" v-model="list[7].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_d bg_color">利息收入</td>
            <td class="center bg_color">9</td>
            <td style="width:25%;"><input type="text" v-model="list[8].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：其他收益</td>
            <td class="center bg_color">10</td>
            <td style="width:25%;"><input type="text" v-model="list[9].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <!-- <tr>
              -->
          <tr>
            <td class="left_b bg_color">投资收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">11</td>
            <td style="width:25%;"><input type="text" v-model="list[10].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">其中：对联营企业和合营企业的投资收益</td>
            <td class="center bg_color">12</td>
            <td style="width:25%;"><input type="text" v-model="list[11].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">以摊余成本计量的金融资产终止确认收益(损失以“﹣”号填列)</td>
            <td class="center bg_color">13</td>
            <td style="width:25%;"><input type="text" v-model="list[12].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">净敞口套期收益(损失以“﹣”号填列)</td>
            <td class="center bg_color">14</td>
            <td style="width:25%;"><input type="text" v-model="list[13].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">公允价值变动收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">15</td>
            <td style="width:25%;"><input type="text" v-model="list[14].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">信用减值损失（损失以“﹣”号填列）</td>
            <td class="center bg_color">16</td>
            <td style="width:25%;"><input type="text" v-model="list[15].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">资产减值损失（损失以“﹣”号填列）</td>
            <td class="center bg_color">17</td>
            <td style="width:25%;"><input type="text" v-model="list[16].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">资产处置收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">18</td>
            <td style="width:25%;"><input type="text" v-model="list[17].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color">二、营业利润（亏损以“﹣”号填列）</td>
            <td class="center bg_color">19</td>
            <td style="width:25%;"><input type="text" v-model="list[18].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：营业外收入</td>
            <td class="center bg_color">20</td>
            <td style="width:25%;"><input type="text" v-model="list[19].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业外支出</td>
            <td class="center bg_color">21</td>
            <td style="width:25%;"><input type="text" v-model="list[20].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color">三、利润总额（亏损总额以“﹣”号填列）</td>
            <td class="center bg_color">22</td>
            <td style="width:25%;"><input type="text" v-model="list[21].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：所得税费用</td>
            <td class="center bg_color">23</td>
            <td style="width:25%;"><input type="text" v-model="list[22].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color">四、净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">24</td>
            <td style="width:25%;"><input type="text" v-model="list[23].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">（一）持续经营净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">25</td>
            <td style="width:25%;"><input type="text" v-model="list[24].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）终止经营净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">26</td>
            <td style="width:25%;"><input type="text" v-model="list[25].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>

          <tr>
            <td class="weight bg_color">五、其他综合收益的税后净额</td>
            <td class="center bg_color">27</td>
            <td style="width:25%;"><input type="text" v-model="list[26].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">（一）不能重分类进损益的其他综合收益</td>
            <td class="center bg_color">28</td>
            <td style="width:25%;"><input type="text" v-model="list[27].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">1.重新计量设定受益计划变动额</td>
            <td class="center bg_color">29</td>
            <td style="width:25%;"><input type="text" v-model="list[28].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">2.权益法下不能转损益的其他利益综合收益</td>
            <td class="center bg_color">30</td>
            <td style="width:25%;"><input type="text" v-model="list[29].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">3.其他权益工具投资公允价值变动</td>
            <td class="center bg_color">31</td>
            <td style="width:25%;"><input type="text" v-model="list[30].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">4．企业自身信用风险公允价值变动</td>
            <td class="center bg_color">32</td>
            <td style="width:25%;"><input type="text" v-model="list[31].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）将重分类进损益的其他综合收益</td>
            <td class="center bg_color">33</td>
            <td style="width:25%;"><input type="text" v-model="list[32].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">1.权益法下可转损益的其他综合收益</td>
            <td class="center bg_color">34</td>
            <td style="width:25%;"><input type="text" v-model="list[33].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">2.其他债权投资公允价值变动</td>
            <td class="center bg_color">35</td>
            <td style="width:25%;"><input type="text" v-model="list[34].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">3.金融资产重分类计入其他综合收益的金额</td>
            <td class="center bg_color">36</td>
            <td style="width:25%;"><input type="text" v-model="list[35].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">4.其他债权投资信用减值准备</td>
            <td class="center bg_color">37</td>
            <td style="width:25%;"><input type="text" v-model="list[36].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">5.现金流量套期储备</td>
            <td class="center bg_color">38</td>
            <td style="width:25%;"><input type="text" v-model="list[37].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_x bg_color">6.外币财务报表折算差额</td>
            <td class="center bg_color">39</td>
            <td style="width:25%;"><input type="text" v-model="list[38].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>

          <tr>
            <td class="weight bg_color">六、综合收益总额</td>
            <td class="center bg_color">40</td>
            <td style="width:25%;"><input type="text" v-model="list[39].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="weight bg_color" >七、每股收益</td>
            <td class="center"></td>
            <td></td>
            <td></td>
            
            <!-- <qzf-td style="width:13%;" :disabled="false"  @change="changeList()"></qzf-td> -->
          </tr>
          
          <tr>
            <td class="left_k bg_color">（一）基本每股收益</td>
            <td class="center bg_color">41</td>
            <td style="width:25%;"><input type="text" v-model="list[40].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）稀释每股收益</td>
            <td class="center bg_color">42</td>
            <td style="width:25%;"><input type="text" v-model="list[41].yearTotal"></td>
            <!-- <td style="width:25%;"><input type="text" v-model="attachArr[0].lastYearTotal"></td> -->
            <td style="width:25%;" class="bg_color"></td>
          </tr>

        </table>
      </el-scrollbar>
      <!-- </div> -->
    </div>
    <div class="save_box">
      <el-button @click="saveLrb" type="primary" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
  
</template>

<script>
import { yearInfo, yearEdit } from '@/api/year'

export default {
  name: 'lybYTable',
  components: { },
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      comName:'',
      taxNo:'',
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(390)
  },

  methods: {
    changeList(){
    },
    getList() {
      yearInfo({comId:this.comId,period:this.startAccountPeriod,tableName:'year_lrb'}).then(res => {
        if(res.data.data){
          this.list = res.data.data;
        }
      });
    },
    saveLrb(){
      this.attachArr.map(v=>{
        v.yearTotal = Number(v.yearTotal)
      })
      yearEdit({comId:this.comId,period:this.startAccountPeriod,tableName:'year_lrb',items:this.list}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
          this.getList()
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  //margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
    padding-right:20px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}

.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
