<template>
  <div>
    <!-- 小企业 -->
    <div v-if="type == '小企业会计准则2013版'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb' && this.tableName != 'kj_xjllb'">
          <zcfzTable ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTable>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTable title="利润统计表" typeName='会计报表' ref="lrbYear" :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTable>
        </el-tab-pane>
        <el-tab-pane label="现金流量表" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashTable title="现金流量表" typeName='会计报表' ref="cash" :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>

      </el-tabs>
    </div>
    <!-- 未执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableTwo ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableTwo ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableTwo>
        </el-tab-pane>
        <el-tab-pane label="现金流量表" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 企业会计制度 -->
    <div v-if="type == '企业会计制度'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableThree ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableThree>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableThree title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableThree>
        </el-tab-pane>
        <el-tab-pane label="现金流量表" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashTable title="现金流量表" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 已执行新金融准则的一般企业 -->
    <div v-if="type == '一般企业会计准则(已执行新金融准则)'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'" >
          <zcfzTableFour ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableFour title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableFour>
        </el-tab-pane>
        <el-tab-pane label="现金流量表" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 一般企业会计准则(全科目) -->
    <div v-if="type == '一般企业会计准则(全科目)'">
      <el-tabs v-model="activeName" style="height: 100%;" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <zcfzTableTwo ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableTwo>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableTwo ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableTwo>
        </el-tab-pane>
        <el-tab-pane label="现金流量表" name="6" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
      </el-tabs>
    </div>
     <!-- 已执行新金融准则的一般企业 -->
     <div v-if="type == '一般企业会计准则(已执行新金融准则)(全科目)'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1" v-if="this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'" >
          <zcfzTableFour ref="zcfzTable" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></zcfzTableFour>
        </el-tab-pane>
        <el-tab-pane label="利润统计表" name="4" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'&& this.tableName != 'kj_xjllb'">
          <lrbYTableFour title="利润统计表" ref="lrbYear" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></lrbYTableFour>
        </el-tab-pane>
        <el-tab-pane label="现金流量表" name="5" v-if="this.tableName != 'kj_zcfz' && this.tableName != 'kj_lrb'">
          <cashQuarterTable title="现金流量表" ref="cash" typeName='会计报表' :startAccountPeriod="startAccountPeriod" :comId="comId"></cashQuarterTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 民办非会计报表 -->
    <!-- <div v-if="type == '民办非'">
      <el-tabs v-model="activeName" style="height: 100%;"  @tab-click="handleClick" type="border-card">
        <el-tab-pane label="资产负债表" name="1">
          <balanceBusiness ref="zcfzTable"></balanceBusiness>
        </el-tab-pane>
        <el-tab-pane label="业务活动表" name="3">
          <businessSheet ref="lrbTableMon"></businessSheet>
        </el-tab-pane>
        <el-tab-pane label="业务活动表" name="2">
          <businessSeason ref="lrbTable"></businessSeason>
        </el-tab-pane>
      </el-tabs>
    </div> -->
  </div>
</template>

<script>
// 小企业
import zcfzTable from "./zcfzTable.vue"
import lrbYTable from "./lrbYTable.vue"

// 未执行新金融准则
import zcfzTableTwo from "./zcfzTable2.vue"
import lrbYTableTwo from "./lrbYTable2.vue"

// 企业会计制度
import zcfzTableThree from "./zcfzTable3.vue"
import lrbYTableThree from "./lrbYTable3.vue"

// 已执行新金融准则
import zcfzTableFour from "./zcfzTable4.vue"
import lrbYTableFour from "./lrbYTable4.vue"

// 民办非
// import balanceBusiness from './tables/balanceBusiness' //民办非资产负债
// import businessSheet from './tables/businessSheet' //民办非业务活动表（月报）
// import businessSeason from './tables/businessSeason' //民办非业务活动表（季报）

// 现金流量表
import cashTable from "./cashTable.vue"
import cashQuarterTable from "./cashQuarterTable.vue"

export default {
  name:'fianceYear',
  components:{
    zcfzTable,
    lrbYTable,
    cashTable,

    zcfzTableTwo,
    lrbYTableTwo,

    zcfzTableThree,
    lrbYTableThree,

    zcfzTableFour,
    lrbYTableFour,
    cashQuarterTable

    // balanceBusiness,
    // businessSheet,
    // businessSeason,
    
  },
  data() {
    return {
      activeName:'1',
      comId:0,
      listQuery:{
        zcfz:1,
        lrb:1,
        xjllb:1
      },
      loading:false,
      // startAccountPeriod:'2023',
      tableName:'',
      bookNames:'',
      mqy:''
    }
  },
  props: {
    comId:{
      default:0,
      type:Number
    },
    type:{
      default:'',
      type:String
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  mounted() {

  },
  created(){
    // this.initBus()

  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '资产负债表'){
        this.$refs.zcfzTable.getList()
      }else if(tab.props.label == '利润统计表'){
        this.$refs.lrbYear.getList()
      }else if(tab.props.label == '现金流量表'){
        this.$refs.cash.getList()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .right_btns{
    position: absolute;
    top: 16px;
    right: 40px;
    z-index: 99;
  }
</style>